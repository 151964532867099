import React from "react";
import { render } from "react-dom";

function Terms() {
  return (
    <div>
      <h1> TERMS & CONDITIONS </h1>
      <p>
        {" "}
        Yetoshi Wakanda Club is a collection of digital artworks (NFTs) running
        on the Ethereum network. This website is only an interface allowing
        participants to exchange digital collectibles. Users are entirely
        responsible for the safety and management of their own private Ethereum
        wallets and validating all transactions and contracts generated by this
        website before approval. Furthermore, as the Yetoshi Wakanda Club smart
        contract runs on the Ethereum network, there is no ability to undo,
        reverse, or restore any transactions. This website and its connected
        services are provided “as is” and “as available” without warranty of any
        kind. By using this website you are accepting sole responsibility for
        any and all transactions involving Yetoshi Wakanda Club digital
        collectibles.
      </p>
      <br />
      <h2>OWNERSHIP</h2>
      <p>
        {" "}
        i. You Own the NFT. Each Yetoshi is an NFT on the Ethereum blockchain.
        When you purchase an NFT, you own the underlying Yetoshi Wakanda, the
        Art, completely. Ownership of the NFT is mediated entirely by the Smart
        Contract and the Ethereum Network: at no point may we seize, freeze, or
        otherwise modify the ownership of any Yetoshi Wakanda. ii. Personal Use.
        Subject to your continued compliance with these Terms, Yetubit grants
        you a worldwide, royalty-free license to use, copy, and display the
        purchased Art, along with any extensions that you choose to create or
        use, solely forthe following purposes: (i) for your own personal,
        non-commercial use; (ii) as part of a marketplace that permits the
        purchase and sale of your Yetoshi Wakanda / NFT, provided that the
        marketplace cryptographically verifies each Yetoshi owner’s rights to
        display the Art for their Yetoshi Wakanda to ensure that only the actual
        owner can display the Art; or (iii) as part of a third party website or
        application that permits the inclusion, involvement, or participation of
        your Yetoshi Wakanda, provided that the website/application
        cryptographically verifies each Yetoshi Wakanda owner’s rights to
        display the Art for their Yetoshi Wakanda to ensure that only the actual
        owner can display the Art, and provided that the Art is no longer
        visible once the owner of the Yetoshi Wakanda leaves the
        website/application. iii. Commercial Use. Subject to your continued
        compliance with these Terms, Yetubit grants you an unlimited, worldwide
        license to use, copy, and display the purchased Art for the purpose of
        creating derivative works based upon the Art (“Commercial Use”).
        Examples of such Commercial Use would e.g. be the use of the Art to
        produce and sell merchandise products (T-Shirts etc.) displaying copies
        of the Art. For the sake of clarity, nothing in this Section will be
        deemed to restrict you from (i) owning or operating a marketplace that
        permits the use and sale of Yetoshis generally, provided that the
        marketplace cryptographically verifies each Yetoshi Wakanda owner’s
        rights to display the Art for their Yetoshi to ensure that only the
        actual owner can display the Art; (ii) owning or operating a third party
        website or application that permits the inclusion, involvement, or
        participation of Yetoshis generally, provided that the third party
        website or application cryptographically verifies each Yetoshi Wakanda
        owner’s rights to display the Art for their Yetoshi to ensure that only
        the actual owner can display the Art, and provided that the Art is no
        longer visible once the owner of the Purchased Yetoshi Wakanda leaves
        the website/application; or (iii) earning revenue from any of the
        foregoing.
      </p>
      <br />
      <h2>Site Availability and Modification</h2>
      <p>
        Although we attempt to provide continuous Site availability to you, we
        do not guarantee that the Site will always be available, work, or be
        accessible at any particular time. We reserve the right to alter,
        modify, update, or remove portions of our Site at any time. We may
        conduct such modifications to our Site for security reasons,
        intellectual property, legal reasons, or various other reasons at our
        discretion; however, nothing in this section obligates us to take
        measures to update the Site for security, legal, or other reasons.
      </p>
      <br />
      <h2>Age</h2>
      <p>
        All users who access the Site or buy Licensed NFTs must be 18 years of
        age or older.
      </p>
      <br />
      <h2>Definitions</h2>
      <p>
        “Yetoshi” is the collection name, and also means any individual or
        entity, aside from Yetubit, that owns the Art. “Art” means any art,
        designs, drawings, traits, layers, and other design elements that may be
        associated with a Licensed NFT that you own. “Content” means text,
        images, audio material, video material, audio-visual material, or other
        forms of media. “Licensed NFT” means a non-fungible token that you Own
        and was originally minted on this Site. “NFT” means any
        blockchain-tracked, non-fungible token. “NFT Trading Platform” means a
        secure marketplace where NFTs are sold, transferred, and recorded on the
        applicable blockchain and such transactions can be proven by the
        applicable blockchain. “Own” or “Ownership” means, with respect to a
        Licensed NFT, a Licensed NFT that you have purchased through the Website
        or otherwise rightfully acquired from a legitimate source, where proof
        of purchase was recorded on the applicable blockchain and ownership of
        the Licensed NFT can be proven. “Owner” means someone who has rightful
        and legal Ownership of a Licensed NFT.
      </p>
      <br />
      <h2>Your Acceptance</h2>
      <p>
        These Terms & Conditions constitute an agreement (“Agreement”) between
        Yetubit (and their licensors and Affiliates), the owner and operator of
        the Yetoshi Wakanda Club website, https://yetoshiwakandaclub.com/ (the
        “Site” or “Website”), and you (“you”, “your” or “user(s)”), a user of
        the Site. Throughout this Agreement, the words “us,” “we,” and “our,”
        refer to our company, Yetubit. BY ACCESSING THIS WEBSITE, YOU AGREE TO
        AND ACCEPT THESE TERMS AND CONDITIONS IN FULL AND WITHOUT RESERVATION.
        IF YOU DISAGREE WITH THESE TERMS & CONDITIONS, YOU MAY NOT USE THIS
        WEBSITE OR ATTEMPT TO PURCHASE ANY YETOSHI WAKANDA CLUB NFTS. We may
        amend this Agreement at any time so please come back and review these
        Terms & Conditions often.
      </p>
    </div>
  );
}

export default Terms;
