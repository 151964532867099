import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div>
      <nav className="nav">
        <ul>
          <li>
            <Link to="./Terms" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
